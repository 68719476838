:root {
  --menu-default-item-height : 3.75rem;
  --menu-icononly-item-height : 2.5rem;

  --menu-item-height : var(--menu-default-item-height);
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
}
.displayModeIconOnly.container {
  padding: 5px 3px;
}

.headerContainer {
  width: 100%;
}
.header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: var(--menu-item-height);
  min-height: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  gap: 20px;
  overflow: hidden;
}

.clickable .header {
  cursor: pointer;
}

.displayModeDefault .header {
  --menu-item-height : var(--menu-default-item-height);
}

.displayModeIconOnly .header {
  --menu-item-height : var(--menu-icononly-item-height);
  justify-content: center;
  width: 100%;
  padding: 0px;
  border-radius: 10px;
}

.header:hover {
  background-color: var(--color-main-grain-brown);
}

.matched .header {
  background-color: var(--color-main-grain-brown);
}

.selected .header {
  background-color: var(--color-main-indian-khaki);
}

.indicator {
  position: absolute;
  height: 100%;
  width: 7px;
  left: 0;
  top: 0;
  background-color: var(--color-main-merino);
  border-radius: 7px;
  display: none;
}

.matched .indicator {
  display: block;
}

.displayModeIconOnly .indicator {
  width: 4px;
}

.icon {
  max-height: 50%;
  width: 1.7rem;
  object-fit: contain;
}

.displayModeIconOnly .icon {
  width: 1.5rem;
}

/* Triangle => http://apps.eky.hk/css-triangle-generator/ */ 
.childIndicator {
  width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 4px;
    border-color: transparent transparent transparent white;
    position: absolute;
    right: 2px;
}

.title {
  flex: 1;
  color: var(--color-main-west-coast);
  font-family: var(--font-signika);
  font-size: 1.2rem;
}

.displayModeIconOnly .title {
  display: none;
}

.openingIcon {
  max-height: 30%;
  width: 15px;
  object-fit: contain;
  opacity: 60%;
  transform: rotate(90deg);
}

.displayModeIconOnly .openingIcon {
  display: none;
}

.opened .openingIcon {
  transform: rotate(180deg);
  transition: ease 0.3s transform;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.displayModeIconOnly .content {
  position: fixed;
  left: 100%;
  top: 0px;
  width: 350px;
  height: 100%;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 50%);
}

.contentBackground {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-main-grain-brown);
  z-index: -1;
}

.displayModeIconOnly .contentBackground {
  backdrop-filter: blur(10px) brightness(100%);
  background-color: unset;
}

.displayModeIconOnly .contentBackground::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-main-indian-khaki);
  opacity: 80%;
}

.contentHeader {
  position: relative;
  background-color: rgba(0, 0, 0, 10%);
  padding: 30px 0px 30px 20px;
  color: var(--color-main-tobacco-brown);
  font-size: 1.8rem;
  text-transform: uppercase;
  font-family: var(--font-halisR);
}

.childContainer {
  width: 100%;
  flex: 1;
  overflow: hidden;
}

.contentFromTitle {
  position: fixed;
  left: calc(100% + 10px);
  box-sizing: border-box;
  height: 30px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.contentTitle {
  color: var(--color-main-tobacco-brown);
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: var(--font-halisR);
  padding: 0px 10px;
  box-sizing: border-box;  
}