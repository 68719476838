
.title {
  color: var(--color-main-black);
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}

.playlistContainer {
  color: var(--color-main-black);
  width: 100%;
  padding: 10px;
  background-color: var(--color-main-grain-brown); 
  border-radius: 10px;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.playlistTitle {
  color: var(--color-main-black);
  width: 100%;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 5px;
}

.playlistSeparator {
  width: 100%;
  height: 1px;
  opacity: 30%;
  background-color: var(--color-main-merino);
}

.playlistSubtitle {
  color: var(--color-main-black);
  width: 100%;
  margin-top: 10px;
  margin-left: 5px;
}

.listContainer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.listItem {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: white; 
  color: var(--color-main-tobacco-brown);
  font-size: 1rem;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  min-height: 0;
}

.tabsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}