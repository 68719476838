/* https://www.w3schools.com/howto/howto_css_blurred_background.asp */


.container {
    background-color: var(--color-main-wheat);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
  }


.backButton {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 3;
    width: 40px;
    padding: 10px;
    -webkit-filter: drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5));
    filter: drop-shadow( 4px 4px 4px rgba(0, 0, 0, .5));
}


.logoutContainer {
    width: 400px;
    padding: 20px;
    max-height: 50%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-main-indian-khaki); 
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 5px #00000055;
    box-sizing: border-box;
}

.title {
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.button {
    margin-top: 20px;
    width: 100%;
    background-color: var(--color-main-green);
    border-radius: 3px;
    height: 35px;
    border: unset;
    box-shadow: 0px 0px 5px #000000AA;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
