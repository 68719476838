:root{
  --page-marge : 20px;
}

@media screen and (max-width: 600px) {
  :root{
    --page-marge : 15px;
  }
}

@media screen and (max-width: 400px) {
  :root{
    --page-marge : 10px;
  }
}

@media screen and (max-width: 300px) {
  :root{
    --page-marge : 5px;
  }
}



.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  padding: 0px var(--page-marge);
  color: white;
  box-sizing: border-box;
  overflow: auto;
  background-color: var(--color-main-merino);
}

.title {
  padding: var(--page-marge) 0px;
}

.loadingContainer {
  width: 100%;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadingIcon {
  width: 30px;
}

.content {
  position: relative;
  width: 100%;
  padding-bottom: var(--page-marge);
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 0px;
}