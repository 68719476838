.socketInfoContainer {
  width: 100%;
  height: 30px;
  box-shadow: 0px 0px 5px #000000AA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: var(--font-halisR);
  gap: 20px;
}

.socketInfoContainerLoading {
  background-color: var(--color-tree-poppy);
}

.socketInfoContainerOffline {
  background-color: var(--color-torch-red);
}

.socketRetryButton {
  font-size: 12px;
  background-color: white;
  border-radius: 3px;
  padding: 2px 15px;
  color: black;
  box-shadow: 0px 0px 5px #000000AA;
}

.react-tabs-content:not(.react-tabs__tab-panel--selected) {
  display: none;
}

.react-tabs__tab {
  color: black;
}

.react-tabs__tab--selected {
  background-color: var(--color-main-indian-khaki) !important;
  font-weight: bold;
}

.react-tabs__tab:focus:after {
  display: none;
}