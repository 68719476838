.sectionTitle {
  color: black;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
}

.detailsContainer {
  width: 100%;
  padding: 0% 5%;
}

.detailsSeparator {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 5px;
  padding: 5px 0px;
}

.detailsSeparatorTitle {
  color: var(--color-main-indian-khaki);
}

.detailsServer {
  color: var(--color-main-west-coast);
  padding-bottom: 2px;
}