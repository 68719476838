:root {
  --menu-subitem-height : 2.5rem;
}

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: var(--menu-subitem-height);
  min-height: var(--menu-subitem-height);
  width: 100%;
  box-sizing: border-box;
  padding-left: 1.25rem;
  gap: 1.25rem;
  cursor: pointer;
  overflow: hidden;
}

.icon {
  max-height: 50%;
  width: 1.25rem;
  margin-left: 0.6rem;
  object-fit: contain;
}

.title {
  color: var(--color-main-tobacco-brown);
  font-family: var(--font-signika);
  font-size: 1rem;
}

.title:hover {
  color: var(--color-main-indian-khaki);
}

.selected .title {
  color: var(--color-main-west-coast);
  text-decoration: underline;
}