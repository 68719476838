:root {
  --table-marge : 1.25rem;
}

.container {
  width: 100%;
  max-height: 100%;
}

.tableContent {
  --table-padding-left: 0px;
  --table-padding-right: 0px;
  --table-padding-top: 0px;
  --table-padding-bottom: 0px;
}
.tableContent {
  border-radius: 10px;
  background-color: var(--color-main-white);
  justify-content: center;
  color: black;
}

.tableHeader {
  padding: 0px var(--table-marge);
  background-color: transparent;
  color: var(--color-main-west-coast)
}
.tableHeaderCell {
  text-align: center;
}
.tableRow {
  height: 2.5rem;
  padding: 0px var(--table-marge);
  color: black;
}
.tableRowCell {
  text-align: center;
  color: black;
}


.tableHeaderCellName, 
.tableCellName {
  justify-content: flex-start;
  text-align: left;
  padding-left: 5px;
}

.tableUndefinedValue {
  color: grey;
}

.button {
  cursor: pointer;
  background-color: unset;
  border: unset;
  font-family: var(--font-halisR);
  font-size: 1.1rem;
  color: var(--color-blue-cornflower);
}

.button:disabled {
  opacity: 0.5;
}

.button.cancel {
  color: var(--color-torch-red);
}

.textOverflow {
  /* overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.textOverflow2Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1rem;
}


.name {
  text-decoration: underline;
  color: var(--color-main-black);
  padding-right: 10px;
  width: 100%;
}

.key {
  text-decoration: underline;
  color: var(--color-main-black);
  padding-right: 10px;
  width: 100%;
}

.app {
  text-decoration: underline;
  color: var(--color-main-black);
}


.recordYes {
  text-decoration: underline;
  font-weight: bold;
  color: var(--color-main-green);
}

.recordFalse {
  color: var(--color-torch-red);
}

.recordLink {
  flex: 1;
  min-width: 0;
}

.redirectionYes {
  text-decoration: underline;
  font-weight: bold;
  color: var(--color-main-green);
}

.redirectionFalse {
  color: var(--color-torch-red);
}

.client {
  text-decoration: underline;
  color: var(--color-main-black);
}

.source {
  text-decoration: underline;
  color: var(--color-main-black);
}
