.sectionTitle {
  color: black;
  font-size: 22px;
  font-weight: bold;
}

.noStream {
  color: var(--color-main-black);
  font-size: 20px;
  width: 100%;
  text-align: center;
}

.listContainer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.listItem {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: white; 
  color: var(--color-main-tobacco-brown);
  font-size: 1rem;
}


.detailsContainer {
  width: 100%;
  padding: 0% 5%;
}

.detailsSeparator {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 5px;
  padding: 5px 0px;
}

.detailsSeparatorTitle {
  color: var(--color-main-indian-khaki);
}

.detailsServer {
  color: var(--color-main-west-coast);
  padding-bottom: 2px;
}

.pullButton {
  padding: 10px;
  background-color: var(--color-mountain-meadow);
  width: 200px;
  text-align: center;
  margin: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.popupTitle {
  font-size: 16px;
  padding: 5px 0px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
}

.popupSubtitle {
  font-size: 16px;
}

.popupInput {
  width: 100%;
  border: 1px solid #00000022;
  border-radius: 5px;
  height: 25px;
}

.popupSelectionContainer {

}

.popupSelectionList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
}

.popupSelectionButton {
  padding: 5px 10px;
  background-color: var(--color-main-wheat);
  border-radius: 5px;
}

.popupSelectionButtonSelected {
  background-color: var(--color-main-green);
}
