
.container {
  position: relative;
}

.input {
  width: 100%;
  padding-right: 25px;
}

.icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
}