.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.displayModeSmall.container {
  gap: 0px;
}

.item {
  width: 100%;
}

.displayModeDefault .deviceList {
  max-height: max(250px, 30vh);
  --scroll-bar-color: var(--scroll-bar-color-dark);
}

.streamBadgeEnabled {
  color: var(--color-main-grain-brown);
  background-color: var(--color-torch-red);
  margin-left: 10px;
  border-radius: 10px;
  padding: 0px 7px;
}

.streamBadgeDisabled {
  color: var(--color-main-indian-khaki);
  background-color: var(--color-main-merino);
  margin-left: 10px;
  border-radius: 10px;
  padding: 0px 7px;
}

.seeAll {
  font-weight: 800;
}