body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  background-color: white;
}


/* To get color name use this site: https://chir.ag/projects/name-that-color/#FFFFFF */
/* Variables */
:root {
  color: black;
  --max-index: 999999;

  /* COLORS */
  --color-main-white : #FFFFFF;
  --color-main-green : #17BF87;
  --color-main-purpule : #5B41F0;
  --color-main-black : #000000;
  --color-main-red : #FF0000;
  --color-main-wheat: #F5DEB3;

  --color-main-merino: #F7F2E8;
  --color-main-grain-brown: #DDCEB0;
  --color-main-indian-khaki: #C4B28F;
  --color-main-tobacco-brown: #645a43;
  --color-main-west-coast: #6B511A;

  --color-mirage : #161E2A;
  --color-galerry : #EBEBEB;

  --color-red : #ED0C0C;
  --color-delete-red : #FF0E0E;
  --color-torch-red : #FF0E43;

  --color-broom : #FFEB20;

  --color-tree-poppy : #FF9820;
  
  --color-clay-ebony : #253144;
  --color-clay-ebony-medium : #222D3F;
  --color-clay-ebony-dark : #202A3A;

  --color-blue-bayoux: #505e75;
  --color-pickled-bluewood : #2A374D;
  --color-pickled-bluewood-dark : #263348;
  --color-blue-prussian : #00245A;
  --color-blue-oxford : #384354;
  --color-blue-dodger : #00BAFF;
  --color-blue-cornflower : #7057FF;
  --color-blue-cornflower-transparent : #7057ff91;

  --color-grey-santas : #9C99B1;
  --color-grey-athens : #F8F8F9;
  --color-grey-dusty : #979797;
  --color-grey-cod : #111111;
  --color-grey-silver-chalice : #ABABAB;

  --color-purpule-heart : #523BD1;

  --color-mountain-meadow : #17bf88;
  --color-salem : #0C835C;

  --color-rose-old : #C69A7E;
  --color-clam-shell : #DBC5B7;

  /* FONTS */
  --font-signika : Signika;
  --font-halisR : HalisR;

  /* SCROLL BAR */
  --scroll-bar-color-dark: var(--color-blue-oxford);
  --scroll-bar-color: var(--color-blue-bayoux); /* colors.getBlueCornflower() */
  --scroll-bar-background-color: rgba(255, 255, 255, 0);


  /* POPUP */
  --popup-background-color: var(--color-main-grain-brown);
  --popup-header-background-color: var(--color-main-indian-khaki);
  --popup-content-background-color: var(--color-main-merino);
  --popup-content-color: var(--color-main-black);
  --popup-close-button-color: var(--color-main-black);
  --popup-button-color: var(--color-main-white);
  --popup-button-validate-color: var(--color-mountain-meadow);
  --popup-button-cancel-color: var(--color-torch-red);
  --popup-button-ok-color: var(--color-main-white);
  --popup-button-disabled-color: grey;
  --popup-content-marge: 1rem;

 /* HOVER */
  --hover-message-background-color: var(--color-main-grain-brown);
  --hover-message-text-color: var(--color-main-black);
}

/* --------------------------------------- 
--------------------------------------- 
            FONTS
--------------------------------------- 
--------------------------------------- 
*/


@font-face {
  font-family: 'Signika';
  src: local('Signika'), url(./assets/font/Signika.ttf) format('truetype');
}
@font-face {
  font-family: 'HalisR';
  src: local('HalisR'), url(./assets/font/HalisR.otf) format('opentype');
}


/* --------------------------------------- 
--------------------------------------- 
              TOAST
--------------------------------------- 
--------------------------------------- 
*/
.toast {
  box-shadow: 0px 2px 10px #00000088;
  --toastify-color-light: var(--color-main-merino);
  --toastify-font-family: var(--font-signika);
}
/*
.toast {
  --toastify-color-light: var(--color-light-mirage);
  --toastify-icon-color-info: var(--color-galerry);
  --toastify-font-family: var(--font-signika);
  --toastify-text-color-light: var(--color-main-white);
  --toastify-color-progress-info: linear-gradient(to right,var(--color-grey-santas),var(--color-galerry));
}*/

:root{
  font-size:14px;
}



@media screen and (max-width: 600px) {
  :root{
    font-size:13px;
  }
}

@media screen and (max-width: 500px) {
  :root{
    font-size:12px;
  }
}

@media screen and (max-width: 400px) {
  :root{
    font-size:11px;
  }
}

@media screen and (max-width: 300px) {
  :root{
    font-size:10px;
  }
}
