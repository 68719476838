:root {
  --table-marge : 1.25rem;
}

.container {
  width: 100%;
  max-height: 100%;
}

.tableContent {
  --table-padding-left: 0px;
  --table-padding-right: 0px;
  --table-padding-top: 0px;
  --table-padding-bottom: 0px;
}
.tableContent {
  border-radius: 10px;
  background-color: var(--color-main-white);
  justify-content: center;
  color: black;
}

.tableHeader {
  padding: 0px var(--table-marge);
  background-color: transparent;
  color: var(--color-main-west-coast)
}
.tableHeaderCell {
  text-align: center;
}
.tableRow {
  height: 2.5rem;
  padding: 0px var(--table-marge);
  color: black;
}
.tableRowCell {
  text-align: center;
  color: black;
}


.tableHeaderCellName, 
.tableCellName {
  justify-content: flex-start;
  text-align: left;
  padding-left: 5px;
}

.tableUndefinedValue {
  color: grey;
}

.button {
  cursor: pointer;
  background-color: unset;
  border: unset;
  font-family: var(--font-halisR);
  font-size: 1.1rem;
  color: var(--color-blue-cornflower);
}

.button:disabled {
  opacity: 0.5;
}

.button.cancel {
  color: var(--color-torch-red);
}

.textOverflow {
  /* overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bypassYes {
  color: var(--color-main-green);
}

.bypassFalse {
  color: var(--color-torch-red);
}


.bitrate {
  text-decoration: underline;
}