
.noStream {
  color: var(--color-main-black);
  font-size: 20px;
  width: 100%;
  text-align: center;
}

.searchRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.refreshButton {
  background-color: var(--color-main-green);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #00000066;
}

.selectorContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.selectorRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
  color: var(--color-main-west-coast);
}