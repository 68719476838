
.title {
  color: var(--color-main-black);
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}

.playlistContainer {
  color: var(--color-main-black);
  width: 100%;
  padding: 10px;
  background-color: var(--color-main-grain-brown); 
  border-radius: 10px;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.playlistTitle {
  color: var(--color-main-black);
  width: 100%;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 5px;
}

.playlistSeparator {
  width: 100%;
  height: 1px;
  opacity: 30%;
  background-color: var(--color-main-merino);
}

.playlistSubtitle {
  color: var(--color-main-black);
  width: 100%;
  margin-top: 10px;
  margin-left: 5px;
}