.sectionTitle {
  color: black;
  font-size: 22px;
  font-weight: bold;
}

.subsectionTitle {
  color: black;
  font-size: 20px;
  margin-bottom: 5px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-main-white); 
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
  padding: 10px 20px;
  min-width: min(200px, 40%);
  height: 100%;
}

.headerDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.detailsTitle {
  color: black;
  font-size: 20px;
}
.detailsValue {
  color: black;
  font-size: 14px;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.startAt {
  color: black;
  font-size: 10px;
  width: 100%;
  text-align: center;
  margin-bottom: 2px;
}

.resetButton {
  width: 100%;
  padding: 5px;
  background-color: var(--color-main-green);
  border-radius: 5px;
  color: white;
  text-align: center;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.container {
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 50px;
}

.openCloseContainer {
  color: grey;
}