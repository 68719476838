/** Checkbox */
:root {
  --checkbox-content-size : 1rem;
  --checkbox-border-width : 0.15rem;
  --checkbox-border-radius : 0.3rem;

  --checkbox-size: calc(var(--checkbox-content-size) + 2 * var(--checkbox-border-width));
}

/* The container */
.customCheckboxContainer {
  display: block;
  position: relative;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customCheckboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.customCheckMarkNormal, .customCheckMarkBar {
  border-radius: var(--checkbox-border-radius);
  border: var(--checkbox-border-width) solid var(--color-main-west-coast);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: var(--checkbox-content-size);
  width: var(--checkbox-content-size);
  background-color: var(--color-main-grain-brown);
}

/* Create the customCheckMark/indicator (hidden when not checked) */
.customCheckMarkNormal:after, .customCheckMarkBar:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the customCheckMark when checked */
.customCheckboxContainer input:checked ~ .customCheckMarkNormal:after, 
.customCheckboxContainer input:checked ~  .customCheckMarkBar:after {
  display: block;
}

/* Style the customCheckMark/indicator */
.customCheckboxContainer .customCheckMarkNormal:after {
  left: 30%;
  top: 0px;
  width: 30%;
  height: 60%;
  border: solid var(--color-main-west-coast);
  border-width: 0 var(--checkbox-border-width) var(--checkbox-border-width) 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the customCheckMark/indicator */
.customCheckboxContainer .customCheckMarkBar:after {
  left: 25%;
  top: 50%;
  width: 55%;
  border: solid #92959b;
  border-width: 0 0 var(--checkbox-border-width) 0;
}