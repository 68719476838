
.refreshButton {
  background-color: var(--color-main-green);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #00000066;
  margin-top: 10px;
  width: 60%;
}

.logsContainer {
  color: black;
  white-space: pre-wrap;
  flex: 1;
  min-height: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  min-height: 0;
}

.tabsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}