.sectionTitle {
  color: black;
  font-size: 22px;
  font-weight: bold;
}

.subsectionTitle {
  color: black;
  font-size: 20px;
  margin-bottom: 5px;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: var(--color-main-white); 
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
  padding: 10px 20px;
  width: 100%;
}

.detailsTitle {
  color: black;
  font-size: 18px;
  min-width: 100px;
  flex: 1;
}
.detailsValue {
  color: black;
  font-size: 28px;
}

.container {
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}