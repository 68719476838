
.mainContainer {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mobileBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  background-color: var(--color-main-tobacco-brown);
}

.mobileBarButton {
  height: 100%;
  padding: 0px 20px 0px 10px;
}

.main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 1px;
}

.rightPane {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.rightPaneContent {
  flex: 1;
  width: 100%;
  background-color: rgb(210, 210, 210);
  /*overflow: auto;*/
  min-height: 0px;
}