.sectionTitle {
  color: black;
  font-size: 22px;
  font-weight: bold;
}

.cardsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.container {
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.detailsContainer { 
}

@media screen and (max-width: 650px) { 
  /* STYLES HERE */
  .cardsContainer {
    flex-direction: column;
  }
  .detailsContainer { 
    width: 100%;
  }
  
}