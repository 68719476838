
.refreshButton {
  background-color: var(--color-main-green);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #00000066;
  margin-top: 10px;
  width: 60%;
}

.logsContainer {
  color: black;
  white-space: pre-wrap;
  flex: 1;
  min-height: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  min-height: 0;
}

.tabsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.row {
  width: 100%;
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.rowTitle {
  width: 250px;
  font-weight: bold;
  font-size: 1.3rem;
  color: var(--color-main-west-coast);
}

.rowValue {
  flex: 1;
  min-width: 0;
  color: var(--color-main-indian-khaki);
  text-align: left;
  font-size: 1.1rem;
}
