.sectionTitle {
  color: black;
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0px;
}

.descriptionRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.descriptionTitle {
  color: var(--color-main-west-coast);
  font-size: 20px;
  white-space: pre;
  min-width: 150px;
}

.descriptionValue {
  color: var(--color-main-west-coast);
  font-size: 16px;
  white-space: pre;
}

.playlistsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #aaaaaa22;
  border-radius: 10px;
  padding: 10px;
}

.playlistsTitle {
  color: var(--color-main-west-coast);
  font-size: 18px;
  white-space: pre;
  margin-bottom: 10px;
  font-weight: bold;
}

.playlists {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.playlistButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: var(--color-main-grain-brown);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000055;
  box-sizing: border-box;
  gap: 10px;
}

.playlistButtonSelected {
  background-color: var(--color-main-tobacco-brown);
}

.playerContainer {
  height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 0;
  margin-top: 20px;
}

.player {
  max-width: 100%;
  height: 100%;
  min-height: 0;
}

.fullscreenPlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.statsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
}
